<header class="header" [ngClass]="{ open: isOpen }" (scroll)="onscroll()" [ngClass]="navbarfixed?'fixed':'nofixed'">
  <app-cookie></app-cookie>
  <div class="container">
    <section class="header__information">
      <div class="header__information__day">
        <span class="header__information__date">
          {{ today | date: "yyyy.MM.dd EEEE":"HU-hu" }},
        </span>
        {{ nameday }} névnap
      </div>
      <div class="header__information__socials">
        <a routerLink="/phonebook" >
          <img
            src="assets/icons/phone-call-icon.svg"
            alt="Hívjon minket"
            class="header__information__socials__phone"
          />
        </a>
        <a [routerLink]="headerData?.contact_static_page_slug">
          <img
            src="assets/icons/dark-icon-envelope.svg"
            alt="Írjon nekünk"
            class="header__information__socials__mail"
          />
        </a>
        <a [href]="headerData?.youtube_url" target="_blank">
          <img
            src="assets/icons/dark-icon-youtube.svg"
            alt="Youtube"
            class="header__information__socials__youtube"
          />
        </a>
        <a [href]="headerData?.facebook_url" target="_blank">
          <img
            src="assets/icons/dark-icon-facebook.svg"
            alt="Facebook"
            class="header__information__socials__facebook"
          />
        </a>
        <img
          src="assets/icons/dark-green-icon-search.svg"
          alt="Keresés megjelenítése"
          class="header__information__socials__search"
          (click)="showSearch()"
        />
      </div>
      <div
        class="header__information__search"
        [ngClass]="{ show: searchToggle }"
      >
        <form (ngSubmit)="onSearch()" #searchForm="ngForm">
          <input
            type="text"
            name="searchQuery"
            placeholder="Keresés"
            title="Keresés"
            aria-label="Keresés"
            class="header__information__search__input"
            required
            [(ngModel)]="searchQuery"
          />
          <button
            type="submit"
            class="header__information__search__button"
            aria-label="Keresés gomb"
          >
            <img
              src="assets/icons/dark-icon-search.svg"
              alt=""
              class="header__information__search__icon"
            />
          </button>
        </form>
      </div>
    </section>
    <nav class="navbar navbar-expand-lg header__navigation" >
      <a class="navbar-brand header__navigation__logo" href="/">
        <img
          src="assets/icons/TelelpulesLogo_cmyk_horizontal.svg"
          alt="Rákosmente logo"
        />
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon header__navigation__icon">
          <span class="navicon"></span>
        </span>
      </button>
      <ul
        class="collapse navbar-collapse header__navigation__menu"
        id="navbarNavDropdown"
      >
        <li
          class="header__navigation__menu__item"
          [ngClass]="{ open: menu.isOpen, active: menu.isActive }"
          *ngFor="let menu of headerData?.menu"
        >
          <ng-container *ngIf="!!menu.sub_menu.length; else navlink">
            <span
              (click)="onMenuClick(menu)"
              (keyup.enter)="onMenuClick(menu)"
              tabindex="0"
              role="button"
            >
              {{ menu.title }}
            </span>
            <ul
              class="header__navigation__submenu header__navigation__submenu--1"
            >
              <li
                class="header__navigation__submenu__item"
                [ngClass]="{ open: subMenu.isOpen, active: subMenu.isActive }"
                *ngFor="let subMenu of menu.sub_menu"
              >
                <ng-container
                  *ngIf="!!subMenu.sub_menu.length; else navlinksub"
                >
                  <span
                    [ngClass]="{
                      'header__navigation__submenu__item--parent':
                        !!subMenu.sub_menu.length
                    }"
                    (click)="onMenuClick(subMenu)"
                    (keyup.enter)="onMenuClick(subMenu)"
                    tabindex="0"
                    role="button"
                  >
                    {{ subMenu.title }}
                  </span>
                  <ul
                    class="
                      header__navigation__submenu header__navigation__submenu--2
                    "
                  >
                    <li
                      class="header__navigation__submenu__item"
                      [ngClass]="{ active: subMenuSecond.isActive }"
                      *ngFor="let subMenuSecond of subMenu.sub_menu"
                    >
                      <a [routerLink]="subMenuSecond.slug || '/'"
                      data-toggle="collapse"
                      data-target="#navbarNavDropdown"
                      >
                        {{ subMenuSecond.title }}
                      </a>
                    </li>
                  </ul>
                </ng-container>
                <ng-template #navlinksub>
                  <a [routerLink]="subMenu.slug || '/'"
                  data-toggle="collapse"
                  data-target="#navbarNavDropdown"
                  >
                    {{ subMenu.title }}
                  </a>
                </ng-template>
              </li>
            </ul>
          </ng-container>
          <ng-template #navlink>
            <a [routerLink]="menu.slug || '/'"
            data-toggle="collapse"
            data-target="#navbarNavDropdown"
            >
              {{ menu.title }}
            </a>
          </ng-template>
        </li>
      </ul>
    </nav>
  </div>
</header>

<app-breadcrumb [breadcrumb]="breadcrumb"></app-breadcrumb>
<app-main>
  <ng-container>
    <div class="case__text">
      <p>Tisztelt Ügyfelünk!</p>
      <p>
        Ügyintézés menüpontunk alatt található ügyleírások útján tájékoztatjuk
        Önt a Polgármesteri Hivatal irodái, egységei által kezelt hatósági
        ügytípusokról, az ügyindítás menetéről és a kapcsolódó tudnivalókról.
        Célunk, hogy Rákosmente Önkormányzatának Polgármesteri Hivatala
        eljárásrendjét átláthatóvá tegye, ezzel is segítve a gyors, gördülékeny
        ügyintézést akár személyes, akár elektronikus úton kezdeményezi azt.
      </p>
      <p>
        Ön az itt található intelligens kereső segítségével a lehető
        leggyorsabban találhatja meg a keresett ügytípust, amely kapcsán eljárni
        kíván nálunk.
      </p>
      <p>
        A kereső mezőbe gépelt kulcsszó (pl. támogatás) alapján a kereső motor
        betűről-betűre szűri az ügyleírásokat. Az Ügyek felirat alatt a szűrés
        után tehát kizárólag azok az ügyleírások jelennek meg, amelyekben
        megtalálható a "támogatás" szó. Ezután a találati listában megjelenő
        ügyleírások közül a keresettre kattintva olvashatja részletes
        tájékoztatásunkat.
      </p>
      <p>
        <strong>
          A személyes ügyfélfogadás az Ügyfélszolgálati Csoport kivételével a Polgármesteri Hivatal valamennyi szervezeti egységében előzetes időpontfoglalás alapján történik.
        </strong>
      </p>
      <p>
        <strong>Időpontot foglalni a +36 1 253-3300-as telefonszámon lehet.</strong>
      </p>
    </div>
    <div class="case__search">
      <form (ngSubmit)="onSearch()" #searchForm="ngForm">
        <input
          type="text"
          name="searchQuery"
          placeholder="Keresés"
          title="Keresés"
          class="case__search__input"
          aria-label="Keresés"
          required
          [(ngModel)]="searchQuery"
        />
        <button type="submit" class="case__search__button" aria-label="Keresés gomb">
          <img
            src="assets/icons/dark-icon-search.svg"
            alt=""
            class="case__search__icon"
          />
        </button>
      </form>
    </div>

    <div class="results-info">
      <h1 class="results-title">Találatok</h1>
      <div class="details text">
        Keresett kifejezés: “{{ query.params.keyword }}”
      </div>
      <div class="details match">
        Találatok összesen: {{ results?.pagination.total || 0 }} db
      </div>
    </div>
    <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
    <div class="result-card" *ngFor="let result of results?.data">
      <div class="result-card__breadcrumb">
        <app-breadcrumb
          [breadcrumb]="result.breadcrumb"
          [lightMode]="true"
        ></app-breadcrumb>
      </div>

      <div class="result__item">
        <app-preview
          [preview]="mainService.transformSearchItemToPreview(result)"
          [wide]="true"
          [minimal]="true"
        ></app-preview>
      </div>
    </div>
    <app-pager
      [pager]="results?.pagination"
      (toPage)="navToPage($event)"
    ></app-pager>
  </ng-container>

  <ng-container aside>
    <app-sidebar-article></app-sidebar-article>
  </ng-container>
</app-main>

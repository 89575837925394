<div class="carousuel">
  <ngb-carousel data-interval="false">
    <ng-template ngbSlide *ngFor="let page of articles">
      <a [routerLink]="page.slug">
      {{ contents }}
      <div class="picsum-img-wrapper">
        <img
          [src]="mainService.getSmallestImageUrl(page.cover_image, maxImgWidth)"
          [alt]="page.cover_image?.alternativeText"
        />
      </div>
        <div class="carousel-caption">
          <div class="h3">{{ page.title }}</div>
          <p>{{ page.lead }}</p>
        </div>
      </a>
    </ng-template>
  </ngb-carousel>
</div>
